<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useSiteStore } from '~/stores/site.store';
import { LockIcon } from '../../design-system/icons/index';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import ExternalIcon from '~/components/design-system/icons/ExternalIcon.vue';
const site = useSiteStore();
const { $t } = useNuxtApp();

function liveChatClicked() {
  site.toggleFeature({ feature: 'displayLiveChat' });
}
</script>

<template>
  <div>
    <div class="mx-2">
      <div class="flex flex-col justify-center items-center flex-wrap mt-3">
        <div class="flex justify-center">
          <div class="lock-circle p-2 flex">
            <LockIcon class="w-10 h-10 text-error-500" />
          </div>
        </div>
        <div>
          <p
            class="text-center my-4 line-height-3"
            v-html="$t('please-note-acc-locked')"
          />
        </div>
      </div>

      <!-- Live chat -->
      <div
        class="flex justify-between bg-light-200 dark:bg-dark-800 rounded-md mb-4 pl-3 cursor-pointer py-1"
        @click="liveChatClicked()"
      >
        <div class="flex items-center mt-0.5 mb-0.5">
          <ExternalIcon class="w-6 mt-1 mr-3" icon-name="chat-support" />
          <p class="my-0 font-bold mr-1 line-height-1">{{ $t('chat') }}</p>
          <p class="my-0 line-height-1">{{ $t('to-live-support') }}</p>
        </div>
        <ChevronRightIcon
          class="w-4 dark:text-light-50 text-dark-700 mr-2 stroke-2 stroke-current"
        />
      </div>
    </div>
    <div class="bg-base dark:bg-layer-1 w-full px-2 py-1">
      <Button
        class="w-full my-2 justify-center"
        type="primary"
        @click="site.deactivateModal()"
      >
        {{ $t('close') }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
p {
  color: #2b303b;
  .dark & {
    color: #fff;
  }
}

.lock-circle {
  border: 6px solid #fc0000;
  border-radius: 100%;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #dae0ed;
  .dark & {
    background-color: #464f60;
  }
}
</style>
